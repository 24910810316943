.markdown-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  .markdown-table th,
  .markdown-table td {
    border: 1px solid rgb(37, 34, 46);
    padding: 8px;
    text-align: left;
  }
  
  .markdown-table th {
    background-color: #06010f;
    font-weight: bold;
  }
  
  .markdown-table tr:nth-child(even) {
    background-color: #03011b;
  }
  
  .markdown-table tr:hover {
    background-color: #07010c;
  }
  

  .hide-sidebar-mobile {
  display: none;
}

@media (min-width: 640px) {
  .show-sidebar {
    display: none;
  }

  .hide-sidebar-mobile {
    display: block; /* Muestra el contenido oculto si la pantalla es menor a 640px */
  }
}
